import request from 'superagent';

export const DELIVERIES_TYPES = {};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const cancelDelivery = ({ bookId } = {}) => {
  return (dispatch) => {
    return request.put(`/api/deliveries/${bookId}/cancel`);
  };
};

export const cancelDeliveries = ({ bookIds } = {}) => {
  return (dispatch) => {
    return request.put(`/api/deliveries/cancel`).send({
      bookIds,
    });
  };
};

export const getDeliveries = ({ page, pageSize, query } = {}) => {
  return (dispatch) => {
    return request
      .get('/api/deliveries')
      .set('x-page', page || 1)
      .set('x-page-size', pageSize || 25)
      .query(query);
  };
};

export const getDeliveriesDoneToday = () => {
  return (dispatch) => {
    return request.get('/api/deliveries/done-today');
  };
};

export const getDeliveriesForPrint = ({ dateFrom, dateTo } = {}) => {
  return (dispatch) => {
    return request.get('/api/deliveries/print').query({ dateFrom, dateTo });
  };
};

export const countDeliveries = ({ page, pageSize, query } = {}) => {
  return (dispatch) => {
    return request
      .get('/api/deliveries/count')
      .set('x-page', page || 1)
      .set('x-page-size', pageSize || 25)
      .query(query);
  };
};

export const getDeliveryByBookId = ({ bookId, receiverMobile } = {}) => {
  return (dispatch) => {
    return request.get(`/api/deliveries/${bookId}/return?receiverMobile=${receiverMobile}`).catch((e) => {
      e.message = e.response.text;
      throw e;
    });
  };
};

export const postReturnDeliveries = ({ bookIds } = {}) => {
  return (dispatch) => {
    return request.post(`/api/deliveries/return/multiple`).send({
      bookIds,
    });
  };
};

export const registerQuick = ({ body } = {}) => {
  return (dispatch) => {
    return request.post('/api/deliveries').send(body);
  };
};

export const registerMultipleQuicks = ({ worksheet, type } = {}) => {
  return (dispatch) => {
    return request
      .post('/api/deliveries/multiple')
      .timeout(1000 * 60 * 5) // Timeout is 5 Minutes
      .send({
        worksheet,
        type: type || 'normal',
      });
  };
};

export const registerMultipleReturnQuicks = ({ worksheet, type } = {}) => {
  return (dispatch) => {
    return request
      .post('/api/deliveries/multiple/returns')
      .timeout(1000 * 60 * 1) // Timeout is 5 Minutes
      .send({
        worksheet,
      });
  };
};

export const registerMultipleQuicksUpdate = ({ worksheet, type } = {}) => {
  return (dispatch) => {
    return request
      .put('/api/deliveries/multiple')
      .timeout(1000 * 60 * 5) // Timeout is 5 Minutes
      .send({
        worksheet,
        type: type || 'normal',
      });
  };
};

export const setPrinted = ({ bookIds } = {}) => {
  return (dispatch) => {
    return request.put('/api/deliveries/print').send({
      bookIds,
    });
  };
};
