import React, { useEffect } from 'react';
import App, { AppContext } from 'next/app';
import Layout from './__layouts/Layout';

import { AUTH_TYPES } from '../redux/reducers/auth';
import { wrapper } from '../redux/store';

import './style.css';

class MyApp extends App {
  state = {
    authenticating: false,
  };

  async getInitialProps(ctx) {
    const pageProps = {
      ...(this.propsComponent.getInitialProps ? await Component.getInitialProps(ctx) : {}),
    };

    return { pageProps };
  }

  render() {
    const { Component, pageProps } = this.props;

    return <Layout>{this.state.authenticating ? <div></div> : <Component {...pageProps} />}</Layout>;
  }
}

export default wrapper.withRedux(MyApp);
